.item__home {
  background-image: url("./assets/images/menu/home.svg");
}
.item__subjects,
.item__reports {
  background-image: url("./assets/images/menu/subjects.svg");
}
.item__doubts {
  background-image: url("./assets/images/menu/doubts.svg");
}
.item__assessments {
  background-image: url("./assets/images/menu/myAssesments.svg");
}
.item__online {
  background-image: url("./assets/images/menu/reports.svg");
}
.item__notes,
.item__study {
  background-image: url("./assets/images/menu/notes.svg");
}
.item__student {
  background-image: url("./assets/images/menu/reset.svg");
}
.item__bulk {
  background-image: url("./assets/images/menu/reset.svg");
}
.item__faculty {
  background-image: url("./assets/images/menu/myAssesments.svg");
}
.item__evaluations {
  background-image: url("./assets/images/menu/evaluations.svg");
}
.login__background {
  background-image: url("./assets/images/background.png");
}
.homeCard__subject {
  background-image: url("./assets/images/subject.png");
}
.homeCard__timing {
  background-image: url("./assets/images/time.png");
}
.react-datepicker__input-container,
.homeCard__date {
  background-image: url("./assets/images/calendar.png");
}
.react-datepicker-wrapper input {
  background-image: url("./assets/images/down-arrow.png");
}
.accordion .box-title:before {
  background-image: url("./assets/images/rightArrow.png");
}
.select::after {
  background-image: url("./assets/images/down-arrow.png");
}
.edit {
  background-image: url("./assets/images/edit.svg");
}
.delete {
  background-image: url("./assets/images/delete.svg");
}
.back {
  background-image: url("./assets/images/leftArrow.png");
}
.close--icon:after {
  background-image: url("./assets/images/close1.png");
}
.selfAssesment__info {
  background-image: url("./assets/images/info.png");
}
.sessionTitle {
  background-image: url("./assets/images/announcement.png");
}
.sessionClass {
  background-image: url("./assets/images/class.png");
}
.sessionTopic {
  background-image: url("./assets/images/topicSelect.png");
}
.sessionDescription {
  background-image: url("./assets/images/sessionDescription.png");
}
.search {
  background-image: url("./assets/images/search.png");
}
